<template>
  <header class="header">
    <div class="container">
      <div class="header__row">
        <div class="header__inner">
          <a
            href="https://noori-tea.ru/"
            target="_blank"
            class="header__logo visible-xs"
          >
            <img
              @click="setMetrika('Клик на кнопку Принцесса Нури')"
              src="../assets/images/logo.svg"
              class="header__logo-img"
              alt=""
            />
          </a>
          <a href="https://monetka.ru/" target="_blank" class="header__monetka visible-xs">
            <img
              src="../assets/images/monetka.svg"
              alt=""
              class="header__monetka-logo"
              @click="setMetrika('Клик на кнопку Монетка')"
            />
          </a>
          <div
            class="header__hamburger"
            @click="showBurger(), gtmEvent('menu_head', 'do', 'internal')"
          >
            <img
              src="../assets/images/icons/hamburger.svg"
              alt=""
              class="header__hamburger-pic"
            />
          </div>
          <button
            class="header__menu_mob-close visible-xs"
            @click="showBurger()"
          ></button>
          <div class="header__nav">
            <a
              href="https://noori-tea.ru/"
              target="_blank"
              class="header__logo"
            >
              <img
                @click="setMetrika('Клик на кнопку Принцесса Нури')"
                src="../assets/images/logo.svg"
                class="header__logo-img"
                alt=""
              />
            </a>
            <a href="https://monetka.ru/" target="_blank" class="header__monetka">
              <img
                src="../assets/images/monetka.svg"
                alt=""
                class="header__monetka-logo"
                @click="setMetrika('Клик на кнопку Монетка')"
              />
            </a>
            <ul class="header__nav-list">
              <li
                v-for="nav in navigation"
                :key="nav.name"
                class="header__nav-link"
                :data-menuanchor="nav.anchor"
                :class="[
                  { active: $route.hash === `#${nav.anchor}` || nav.active },
                ]"
              >
                <router-link
                  v-if="nav.anchor"
                  :to="{ path: `/${locationSearch}#${nav.anchor}` }"
                >
                  {{ nav.name }}
                </router-link>
                <a
                  v-else-if="nav.path"
                  :href="publicPath + 'docs/' + nav.path"
                  class="d-flex aic"
                  target="_blank"
                >
                  {{ nav.name }}
                  <svg
                    width="11"
                    height="13"
                    viewBox="0 0 11 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.2 1C6.2 0.613401 5.8866 0.3 5.5 0.3C5.1134 0.3 4.8 0.613401 4.8 1L6.2 1ZM5.00503 10.945C5.27839 11.2183 5.72161 11.2183 5.99498 10.945L10.4497 6.4902C10.7231 6.21684 10.7231 5.77362 10.4497 5.50025C10.1764 5.22689 9.73316 5.22689 9.4598 5.50025L5.5 9.46005L1.5402 5.50025C1.26684 5.22689 0.82362 5.22689 0.550253 5.50025C0.276886 5.77362 0.276886 6.21684 0.550253 6.4902L5.00503 10.945ZM4.8 1L4.8 10.45L6.2 10.45L6.2 1L4.8 1Z"
                      fill="#F1C27A"
                    />
                    <line
                      x1="0.7"
                      y1="11.95"
                      x2="10.3"
                      y2="11.95"
                      stroke="#F1C27A"
                      stroke-width="1.4"
                      stroke-linecap="round"
                    />
                  </svg>
                </a>
                <router-link v-else :to="{ path: `/${locationSearch}` }">
                  {{ nav.name }}
                </router-link>
              </li>
            </ul>
            <div class="header__dropdown-wrapper" v-if="user">
              <router-link
                tag="button"
                to="/profile"
                class="header__login btn btn--primary"
                @click.native="setMetrika('Клик на кнопку Личный кабинет')"
              >
                <img
                  class="header__login-icon"
                  src="../assets/images/icons/user.svg"
                  alt=""
                />
                <span>{{ user.first_name || "Личный кабинет" }} </span>
              </router-link>
              <ul class="header__dropdown header__nav-info">
                <li
                  class="header__dropdown-item header__nav-info-item _pointer"
                  @click="showModal('new-password')"
                >
                  <img src="../assets/images/icons/lock-2.svg" alt="" />
                  <span>сменить пароль</span>
                </li>
                <li
                  class="header__dropdown-item header__nav-info-item _pointer"
                  @click="logout()"
                >
                  <img src="../assets/images/icons/exit-2.svg" alt="" />
                  <span>выход</span>
                </li>
              </ul>
            </div>
            <div
              v-else
              class="header__login btn btn--primary"
              @click.prevent="showModal('authorization'), setMetrika('Клик на кнопку Личный кабинет')"
            >
              <img
                class="header__login-icon"
                src="../assets/images/icons/user.svg"
                alt=""
              />
              <span>личный кабинет</span>
            </div>
          </div>
        </div>

        <div class="header__menu_mob">
          <ul class="header__nav">
            <li
              v-for="nav in navigation"
              :key="nav.name"
              class="header__nav-link"
            >
              <router-link
                v-if="nav.anchor"
                :to="{ path: `/${locationSearch}#${nav.anchor}` }"
                @click.native="showBurger()"
              >
                {{ nav.name }}
              </router-link>
              <a
                v-else-if="nav.path"
                :href="publicPath + 'docs/' + nav.path"
                target="_blank"
                class="d-flex aic jcc"
              >
                {{ nav.name }}
                <svg
                  width="11"
                  height="13"
                  viewBox="0 0 11 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.2 1C6.2 0.613401 5.8866 0.3 5.5 0.3C5.1134 0.3 4.8 0.613401 4.8 1L6.2 1ZM5.00503 10.945C5.27839 11.2183 5.72161 11.2183 5.99498 10.945L10.4497 6.4902C10.7231 6.21684 10.7231 5.77362 10.4497 5.50025C10.1764 5.22689 9.73316 5.22689 9.4598 5.50025L5.5 9.46005L1.5402 5.50025C1.26684 5.22689 0.82362 5.22689 0.550253 5.50025C0.276886 5.77362 0.276886 6.21684 0.550253 6.4902L5.00503 10.945ZM4.8 1L4.8 10.45L6.2 10.45L6.2 1L4.8 1Z"
                    fill="#F1C27A"
                  />
                  <line
                    x1="0.7"
                    y1="11.95"
                    x2="10.3"
                    y2="11.95"
                    stroke="#F1C27A"
                    stroke-width="1.4"
                    stroke-linecap="round"
                  />
                </svg>
              </a>
              <router-link v-else :to="{ path: `/${locationSearch}` }">
                {{ nav.name }}
              </router-link>
            </li>

            <router-link
              tag="li"
              class="btn btn--primary"
              to="/profile"
              v-if="user"
              @click.native="showBurger(), setMetrika('Клик на кнопку Личный кабинет')"
            >
              <span>личный кабинет</span>
            </router-link>
            <li
              @click.prevent="showModal('authorization'), showBurger(), setMetrika('Клик на кнопку Личный кабинет')"
              v-else
              class="btn btn--primary"
            >
              <span>личный кабинет</span>
            </li>
          </ul>
          <ul class="header__nav-info" v-if="user">
            <li class="header__nav-info-item" @click="showModal('new-password')">
              <img src="../assets/images/icons/lock.svg" alt="" />
              <span>сменить пароль</span>
            </li>
            <li class="header__nav-info-item" @click="logout()">
              <img src="../assets/images/icons/exit.svg" alt="" />
              <span>выход</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  props: ["headerModified"],
  data() {
    return {
      publicPath: process.env.BASE_URL,
      phone: "7 951 376-98-787",
      email: "Ivanov_ivan@mail.ru",
      locationSearch: "",
      navigation: [
        {
          name: "Главная",
          anchor: "home",
          active: true,
        },
        {
          name: "как принять участие",
          anchor: "steps",
          gtm: true,
          gtmText: "part",
        },
        {
          name: "призы",
          anchor: "prizes",
          gtm: true,
          gtmText: "prizes",
        },
        {
          name: "победители",
          anchor: "winners",
          gtm: true,
          gtmText: "winners",
        },
        {
          name: "вопрос · ответ",
          anchor: "faq",
          gtm: true,
          gtmText: "faq",
        },
      ],
    };
  },
  computed: {
    user() {
      console.log(this.$store.getters.user);
      return this.$store.getters.user;
    },
    homePage() {
      if (this.$route.path === "/") {
        return true;
      } else {
        return false;
      }
    },
  },

  methods: {
    showModal(name) {
      this.$modal.show(name);
    },
    logout() {
      this.$store.dispatch("LogOut");
      document.location.reload();
    },
    showBurger() {
      let header = document.querySelector(".header");
      if (!header.classList.contains("header--mob_active"))
        header.classList.add("header--mob_active");
      else header.classList.remove("header--mob_active");
    },
    toFeedback() {
      this.$modal.show("feedback");
    },
    wordVariant(items) {
      return items === 1 ? "сила" : items > 1 && 4 > items ? "силы" : "сил";
    },
  },
  mounted() {
    if (window.location.search.indexOf("utm_source") > -1) {
      this.locationSearch = window.location.search;
    }
  },
};
</script>

<style scoped lang="scss">
.header {
  position: fixed;
  top: 0px;
  left: 0;
  width: 100%;
  z-index: 100;
  transition: 0.3s;
  &._modified {
  }
  .container {
    position: relative;
    padding-top: 0;
    padding-bottom: rem(0px);
    .header--mob_active & {
      box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.25);
    }
  }
  &__row {
    display: flex;
    align-items: center;
    width: 100%;
  }
  &.header--mob_active {
    .header__hamburger {
      opacity: 0;
      visibility: hidden;
    }

    .header__menu_mob {
      display: flex;
    }
    .header__menu_mob-close {
      opacity: 1;
    }
  }

  &__inner {
    position: relative;
    width: 100%;
    display: flex;
    align-items: flex-start;
    z-index: 10;
  }
  &__nav {
    position: relative;
    display: none;
    width: 100%;
    align-items: center;
    flex-direction: column;
    &-info {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: rem(24px);
      &-item {
        display: flex;
        align-items: center;
        font-size: rem(14px);
        font-family: $bold;
        text-transform: uppercase;
        &:not(:last-of-type) {
          margin-bottom: rem(13px);
        }
        svg,
        img {
          width: rem(16px);
          margin-right: rem(8px);
        }
      }
    }
  }

  &__nav-link {
    margin-bottom: 0;
    font-size: rem(20px);
    line-height: normal;
    text-align: center;
    white-space: nowrap;
    transition: all 0.3s ease-in-out;
  }

  &__nav-retail {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
  }
  &__nav-retail-img {
    max-height: rem(35px);
    margin-bottom: rem(30px);
  }
  &__hamburger {
    position: absolute;
    top: rem(16px);
    right: 0;
    display: block;
    width: rem(29px);
    margin: 0;
    img {
      width: 100%;
    }
  }
  &__menu_mob {
    display: none;
    position: absolute;
    width: 100%;
    top: 0px;
    right: 0;
    height: calc(var(--vhH, 1vh) * 100);
    flex-direction: column;
    align-items: center;
    background: url("../assets/images/menu-bg.png") no-repeat center;
    background-size: cover;
    color: #fff;
    padding: calc(var(--vhH, 1vh) * 17) rem(23px) rem(60px);
    z-index: 9;
    .btn {
      min-width: auto;
      margin: auto auto 0;
    }
    .header__nav {
      display: flex;
      height: 100%;
      &-item {
        width: auto;
      }
      &-link {
        margin-bottom: rem(27px);
        font-size: rem(18px);
        text-align: center;
        white-space: nowrap;
        text-transform: uppercase;
        color: #fff;
        font-family: $medium;
        transition: all 0.3s ease-in-out;
        svg,
        img {
          width: rem(24px);
          margin-right: rem(10px);
        }
        &._profile {
          display: flex;
          align-items: center;
          margin-top: auto;
          justify-content: center;
          font-size: rem(16px);
          padding: rem(14px) rem(33px);
          border: rem(2px) solid #fff;
          border-radius: rem(30px);
        }
      }
      &-info {
        &-item {
          font-family: $medium;
        }
      }
    }
  }
  &__logo {
    flex-grow: 0;
    flex-shrink: 0;
    margin-left: rem(-20px);
    &-img {
      height: rem(55px);
    }
  }
  &__monetka {
    &-logo {
      height: rem(55px);
    }
  }
  &__login {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    text-transform: uppercase;
    transition: 0.4s;
    z-index: 2;
    &-icon {
      height: rem(30px);
      margin-right: rem(10px);
      object-fit: contain;
      transition: 0.4s;
    }
  }
  &__menu_mob-close {
    position: absolute;
    top: rem(16px);
    right: rem(0px);
    cursor: pointer;
    align-self: flex-end;
    width: rem(24px);
    height: rem(24px);
    flex-shrink: 0;
    margin-bottom: rem(30px);
    background: url("../assets/images/icons/close-white.svg") no-repeat center;
    background-size: 100%;
    opacity: 0;
    z-index: 10;
  }
  @media (min-width: $sm) {
    &__menu_mob {
      max-width: 55%;
      box-shadow: -5px 4px 10px rgba(0, 0, 0, 0.15);
    }
  }
  @media (min-width: $md) {
    .container {
      padding-top: 0;
      padding-bottom: 0;
      display: flex;
    }
    &__login {
      display: flex;
      align-items: center;
      padding: 0 rem(20px);
      margin: auto rem(15px) auto auto;
      font-size: rem(18px);
      line-height: 1;
      letter-spacing: rem(1px);
      a {
        margin-top: rem(-3px);
      }
      &-icon {
      }
      &::after {
        display: none;
      }
    }
    &__row {
      align-items: flex-start;
    }
    &__menu-logos {
      display: none;
    }
    &__logo {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-grow: 0;
      flex-shrink: 0;
      height: vw(102px);
      margin: 0;
      transition: 0.4s;

      &-img {
        height: 100%;
      }
    }
    &__monetka {
      height: vw(102px);
      &-logo {
        height: 100%;
      }
    }
    &__nav {
      display: flex;
      align-items: flex-start;
      flex-direction: row;
      height: vw(80px);
      background: #fff;
      border-radius: 0 0 rem(20px) rem(20px);
      &_mob {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      &-list {
        display: flex;
        justify-content: center;
        flex-direction: row;
        align-items: center;
        margin: auto;
        transition: 0.4s;
      }
      &-link {
        position: relative;
        font-size: rem(16px);
        margin: 0;
        line-height: 1;
        padding-bottom: rem(2px);
        text-transform: uppercase;
        font-family: $medium;
        text-align: center;
        letter-spacing: 0.02em;
        transition: all 0.3s ease-in-out;
        border-bottom: 1px solid transparent;
        cursor: pointer;
        &:not(:last-of-type) {
          margin-right: vw(60px);
        }
        &.active,
        &.router-link-exact-active {
          color: $red;
          cursor: default;
          path {
            fill: $green_dark;
          }
        }
        svg {
          margin-left: rem(5px);
        }
      }
    }
    &__dropdown {
      position: absolute;
      top: 90%;
      right: rem(0px);
      min-width: rem(256px);
      display: block;
      background: #fff;
      font-size: rem(16px);
      box-shadow: 0px 0px rem(22px) rgba(0, 0, 0, 0.3);
      border-radius: rem(10px);
      transition: 0.4s;
      opacity: 0;
      z-index: 100;
      pointer-events: none !important;
      cursor: default;
      &::after {
        content: "";
        display: block;
        position: absolute;
        bottom: 100%;
        right: 0;
        width: 100%;
        height: rem(26px);
      }
      &::before {
        content: "";
        display: block;
        width: rem(15px);
        height: rem(15px);
        position: absolute;
        top: rem(-7px);
        right: rem(27px);
        transform: rotate(50deg) skew(15deg, 0deg);
        background: #fff;
      }
      &-item {
        flex-shrink: 0;
        padding: rem(15px) rem(25px);
        font-family: $medium;
        font-weight: 500;
        font-size: rem(16px);
        &:not(:last-of-type) {
          margin-bottom: 0;
          border-bottom: 1px solid $brown;
        }
        strong {
          font-size: rem(22px);
          font-weight: 900;
        }
        input {
          border: none;
          background: none;
          outline: none;
          padding: 0;
          text-align: center;
          font-size: rem(16px);
          font-weight: 700;
        }
        img {
          margin-right: rem(15px);
        }
      }
      &-wrapper {
        position: relative;
        margin: auto 0;
        &:hover {
          & > .header__dropdown {
            opacity: 1;
            pointer-events: all !important;
          }
        }
      }
    }
    &__hamburger,
    &__menu_mob {
      display: none;
    }
  }
}

.header__menu_mob {
  display: none;
  input {
    background: none;
    border: none;
    outline: none;
    color: #fff;
    margin-right: rem(-30px);
    font-weight: 700;
    font-size: rem(14px);
    opacity: 1;
    &::placeholder {
      opacity: 1;
      color: #fff;
    }
  }
}

.header__menu_mob.active {
  display: flex !important;
}
</style>
