var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',{staticClass:"recover"},[_c('inputText',{class:{
      error: _vm.validationStatus(_vm.$v.new_password) || _vm.errorMessage.new_password,
    },attrs:{"label":"новый пароль","type":"password","maxlength":"24","placeholder":"••••••","field":_vm.new_password,"error":(!_vm.$v.new_password.required && _vm.$v.new_password.$error
        ? 'Обязательное поле'
        : !_vm.$v.new_password.minLength && _vm.$v.new_password.$error
        ? 'Минимум 6 символов'
        : !_vm.$v.new_password.maxLength && _vm.$v.new_password.$error
        ? 'Максимум 8 символа'
        : '') || _vm.errorMessage.new_password},on:{"input":function($event){_vm.new_password = $event}}}),_c('inputText',{class:{
      error: _vm.validationStatus(_vm.$v.confirm_new_password) || _vm.errorMessage.confirm_new_password,
    },attrs:{"label":"повторите пароль","type":"password","maxlength":"24","placeholder":"••••••","field":_vm.confirm_new_password,"error":(!_vm.$v.confirm_new_password.required && _vm.$v.confirm_new_password.$error
        ? 'Обязательное поле'
        : !_vm.$v.confirm_new_password.minLength && _vm.$v.confirm_new_password.$error
        ? 'Минимум 6 символов'
        : !_vm.$v.confirm_new_password.maxLength && _vm.$v.confirm_new_password.$error
        ? 'Максимум 24 символа'
        : '') || _vm.errorMessage.confirm_new_password},on:{"input":function($event){_vm.confirm_new_password = $event}}}),_c('div',{staticClass:"recover__footer"},[_c('button',{staticClass:"btn btn--primary",class:{ loading: _vm.submitStatus == 'PENDING' },attrs:{"type":"button"},on:{"click":function($event){return _vm.submit()}}},[_vm._v(" Отправить"),_c('img',{staticClass:"loader",attrs:{"src":require("../../assets/img/spinner.svg"),"alt":""}})])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }