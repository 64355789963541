<template>
  <footer class="footer">
    <div class="footer__content">
      <div class="container">
        <ul class="footer__docs">
          <li v-for="(item, idx) in docs" :key="idx" class="footer__docs-item">
            <a
              :href="publicPath + 'docs/' + item.path"
              class="footer__docs-link"
              target="_blank"
              @click="
                item.gtm
                  ? (gtmEvent('rules', 'click', 'send'),
                    gtmEvent('rules', 'click', 'internal'),
                    setMetrika('Клик на кнопку Полные правила акции'))
                  : ''
              "
            >
              {{ item.name }}
            </a>
          </li>
          <li class="footer__docs-item" @click="$modal.show('feedback')">
            <span>обратная связь</span>
          </li>
        </ul>
        <div class="footer__text">
          <p>
            Для участия в&nbsp;акции необходимо: в&nbsp;период
            с&nbsp;2&nbsp;сентября по&nbsp;3&nbsp;ноября 2024&nbsp;г. приобрести
            в&nbsp;магазинах торговой сети &laquo;Монетка&raquo; любой чай
            &laquo;Принцесса Нури&raquo; и&nbsp;зарегистрировать чек
            с&nbsp;покупкой на&nbsp;сайте
            <a
              href="https://www.noori-tea-promo.ru"
              class="_undeline-link"
              target="_blank"
              >noori-tea-promo.ru</a
            >. Общий срок проведения акции (включая срок выдачи призов):
            с&nbsp;2&nbsp;сентября по&nbsp;30&nbsp;ноября 2024&nbsp;г.
            Количество призов ограничено. Призы могут отличаться
            от&nbsp;изображений на&nbsp;рекламных материалах. Информация
            об&nbsp;организаторе акции, сроках и&nbsp;правилах
            ее&nbsp;проведения, количестве призов, сроках, месте и&nbsp;порядке
            вручения призов размещена на&nbsp;сайте
            <a
              href="https://www.noori-tea-promo.ru"
              class="_undeline-link"
              target="_blank"
              >noori-tea-promo.ru</a
            >.
          </p>
        </div>
        <!-- <div class="footer__copyright">
          © <span>{{ year }}</span> Все права защищены.
        </div> -->
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  components: {},
  props: ["partners"],
  data() {
    return {
      publicPath: process.env.BASE_URL,
      docs: [
        {
          name: "правила акции",
          path: "rules.pdf",
          gtm: true,
        },
        {
          name: "политика конфиденциальности",
          path: "terms-of-use.pdf",
        },
      ],
    };
  },
  methods: {
    scrollToTop() {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },
    scrollTo() {
      const faq = document.getElementById("faq").offsetTop;
      window.scroll({
        top: faq,
        left: 0,
        behavior: "smooth",
      });
    },
  },
  computed: {
    year() {
      let date = new Date();
      return date.getFullYear();
    },
  },
};
</script>
<style scoped lang="scss">
.footer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: rem(-123px);
  padding: rem(132px) rem(7px) rem(45px);
  border-bottom: none;
  background: url("../assets/images/footer.svg") no-repeat center top;
  background-size: 100%;
  color: #fff;
  overflow: hidden;
  pointer-events: none;
  z-index: 10;
  * {
    pointer-events: all;
  }
  &__row,
  &__docs {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__docs {
    &-item {
      margin-bottom: rem(30px);
      font-size: rem(16px);
      line-height: 1;
      text-transform: uppercase;
      font-family: $medium;
      letter-spacing: 0.06em;
    }
  }
  &__text,
  &__copyright {
    font-size: rem(14px);
    line-height: rem(17.9px);
    text-align: center;
    opacity: 0.7;
    p {
      margin-bottom: rem(5px);
      // overflow-wrap: break-word;
      // word-wrap: break-word;
      // -webkit-hyphens: auto;
      // -ms-hyphens: auto;
      // -moz-hyphens: auto;
      // hyphens: auto;
    }
    a {
      white-space: nowrap;
    }
  }
  &__col {
    width: 100%;
  }
  &__copyright {
    margin-top: rem(12px);
  }
  @media (max-width: $sm) {
    .container {
      padding-left: rem(13px);
      padding-right: rem(17px);
    }
  }
  @media (min-width: $sm) {
    margin-top: rem(-270px);
    padding: rem(305px) 0 rem(50px);
    font-size: rem(13px);
    background: url("../assets/images/footer-d.png") no-repeat center top;
    background-size: 100%;
    text-align: justify;
    .container {
      width: rem(1150px);
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
    &__content {
      padding-top: 0;
      background: none;
    }
    &__row,
    &__docs {
      flex-direction: row;
      justify-content: space-between;
    }
    &__docs {
      justify-content: space-around;
      margin-bottom: rem(36px);
      text-transform: uppercase;
      font-size: rem(16px);
      &-item {
        margin-bottom: 0;
        line-height: normal;
        cursor: pointer;
      }
    }
    &__copyright {
      margin-top: 0;
      text-align: left;
      font-size: rem(16px);
    }
    &__text {
      font-size: rem(16px);
      line-height: 1.1;
    }
    &__col {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      &:last-of-type {
        flex: 0 0 58%;
      }
    }
  }
}
</style>
