<template>
  <div class="home">
    <Hero />
    <Steps />
    <Prizes />
    <Winners />
    <Faq />
  </div>
</template>

<script>
// @ is an alias to /src
import Hero from "@/components/AppHero.vue";
import Steps from "@/components/AppSteps.vue";
import Prizes from "@/components/AppPrizes.vue";
import Faq from "@/components/AppFaq.vue";
import Winners from "../components/AppWinners.vue";

export default {
  name: "Home",
  components: {
    Hero,
    Steps,
    Prizes,
    Faq,
    Winners,
  },
  data: function () {
    return {
      publicPath: process.env.BASE_URL,
      sectionObserver: null,
    };
  },
  mounted() {
    // this.observeSections();
    // this.$modal.show("authorization");
    const t = this;
    let doGoal25 = true;
    let doGoal50 = true;
    let doGoal75 = true;
    let doGoal100 = true;
    this.$parent.showHeader = true;
    this.$parent.showFooter = true;
    window.addEventListener("scroll", function () {
      // if (window.scrollY > 100) {
      //   document.querySelector(".header").classList.add("_modified");
      // } else {
      //   document.querySelector(".header").classList.remove("_modified");
      // }
      if (
        window.innerHeight + Math.round(window.scrollY) >=
        document.body.offsetHeight / 4
      ) {
        if (doGoal25) {
          t.setMetrika('Скролл страницы 25')
          doGoal25 = false;
        }
      }
      if (
        window.innerHeight + Math.round(window.scrollY) >=
        document.body.offsetHeight / 2
      ) {
        if (doGoal50) {
          t.setMetrika('Скролл страницы 50')
          doGoal50 = false;
        }
      }
      if (
        window.innerHeight + Math.round(window.scrollY) >=
        (document.body.offsetHeight / 4) * 3
      ) {
        if (doGoal75) {
          t.setMetrika('Скролл страницы 75')
          doGoal75 = false;
        }
      }
      if (
        window.innerHeight + Math.round(window.scrollY) >=
        document.body.offsetHeight
      ) {
        if (doGoal100) {
          t.setMetrika('Скролл в Footer')
          doGoal100 = false;
        }
      }
    });
    if (window.innerWidth >= 1024) {
      const sections = document.querySelectorAll(".section");
      const links = document.querySelectorAll("[data-menuanchor]");
      document.addEventListener("scroll", (e) => {
        sections.forEach((ha) => {
          const rect = ha.getBoundingClientRect();
          if (
            (rect.top > 0 && rect.top < 100) ||
            (rect.bottom > 0 && rect.bottom < 1000)
          ) {
            const location = window.location.toString().split("#")[0];
            history.replaceState(null, null, location + "#" + ha.id);
            links.forEach((element) => {
              element.classList.remove("active");
            });
            links.forEach((element) => {
              element.classList.remove("active");
            });
            document
              .querySelector(`[data-menuanchor='${ha.id}']`)
              .classList.add("active");
            document.getElementById(ha.id).classList.add("active");
          }
          // console.log(rect);
        });
        if (window.scrollY === 0 && this.$route.name === "Home") {
          const location = window.location.toString().split("#")[0];
          links.forEach((element) => {
            element.classList.remove("active");
          });
          history.replaceState(null, null, location + "#home");
          document
            .querySelector(`[data-menuanchor='home']`)
            .classList.add("active");
        }
      });
    }
    const params = this.$route.query;
    if (params.email === "0") {
      let socId = params.identity_vk || params.identity_ok || "";

      console.log(socId);

      if (socId.length < 1) {
        this.$modal.show("common_error", {
          title: "Что-то пошло не так!",
        });
        this.$router.push("/");
      } else {
        this.$store.commit("SIGNUP_SOC_ID", socId);
        this.$modal.show("soc_signup");
        this.$router.push({
          name: "Home",
        });
      }
    } else if (params.first === "1") {
      this.$store.commit("SET_TOKEN", params.auth_key);
      this.$store.dispatch("GetProfile").then((r) => {
        this.$modal.show("soc_signup-finish", {
          userName: r.data.first_name,
        });
        this.$router.push({
          name: "Home",
        });
      });
    } else if (params.result === "1") {
      this.$store.commit("SET_TOKEN", params.auth_key);

      this.$store.dispatch("GetProfile").then(() => {
        this.$router.push("/");
      });
    }
  },
  methods: {
    observeSections() {
      try {
        this.sectionObserver.disconnect();
      } catch (error) {
        console.log(error);
      }

      const options = {
        rootMargin: "0px 0px",
        threshold: 0,
      };
      this.sectionObserver = new IntersectionObserver(
        this.sectionObserverHandler,
        options
      );

      // Observe each section
      const sections = document.querySelectorAll(".section");
      sections.forEach((section) => {
        this.sectionObserver.observe(section);
      });
    },
    sectionObserverHandler(entries) {
      for (const entry of entries) {
        if (entry.isIntersecting) {
          const sectionId = entry.target.id;
          // Push sectionId to router here
          this.$router.replace({
            name: this.$route.name,
            hash: `#${sectionId}`,
          });
          console.log(sectionId);
          // this.$route.hash = `#${sectionId}`;

          console.log(this.$route.hash);
        }
      }
    },
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
  },
  created() {},
};
</script>
<style lang="scss">
.home {
  //background: url("@/assets/images/background-mobile.jpg") no-repeat center top;
  background-size: 100%;
  @media (min-width: $sm) {
    // background: url("@/assets/images/background.jpg") no-repeat center top;
    background-size: 100%;
  }
}
p + .tg {
  margin-top: rem(20px);
}
.tg {
  border-collapse: collapse;
  border-color: #ccc;
  border-spacing: 0;
  &-wrapper {
    padding-bottom: rem(10px);
    margin-bottom: rem(20px);
    overflow-x: auto;
    &::-webkit-scrollbar {
      width: rem(10px);
      height: rem(5px);
    }
    /* Track */
    &::-webkit-scrollbar-track {
      border-radius: 10px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: $green_dark;
      border-radius: 10px;
      transition: all 0.3s ease-in-out;
    }
  }
  & + p {
    margin-top: rem(20px);
  }
}
.tg td {
  background-color: #fff;
  border-color: #ccc;
  border-style: solid;
  border-width: 1px;
  font-size: rem(16px);
  font-weight: 400;
  overflow: hidden;
  padding: rem(8px) rem(10px);
  word-break: normal;
}
.tg th {
  background-color: #f0f0f0;
  border-color: #ccc;
  border-style: solid;
  border-width: 1px;
  text-align: center;
  font-size: rem(16px);
  overflow: hidden;
  padding: rem(10px) rem(10px);
  word-break: normal;
}
.tg .tg-buh4 {
  background-color: #f9f9f9;
  vertical-align: top;
}
.tg .tg-0pky {
  vertical-align: top;
}
.tg .tg-0lax {
  vertical-align: top;
}
</style>
