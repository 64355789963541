import Vue from "vue";
import App from "./App.vue";
import "jquery";

import router from "./router";
import store from "./store";
import "./assets/tailwind.css";
import "./assets/styles/main.scss";
import VModal from "vue-js-modal";

import typograf from "./utils/filters";
import VueScrollactive from "vue-scrollactive";
import vSelect from "vue-select";
import "vue-select/src/scss/vue-select.scss";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import VTooltip from "v-tooltip";
import VueSmoothScroll from "vue2-smooth-scroll";
import Vuelidate from "vuelidate";
import VueTheMask from "vue-the-mask";
import LoadScript from "vue-plugin-load-script";
import DadataSuggestions from "vue-dadata-suggestions";
import VueMeta from "vue-meta";
import VueResource from "vue-resource";
import VueYandexMetrika from "vue-yandex-metrika";

Vue.use(VueResource);

Vue.use(VueMeta);
Vue.use(DadataSuggestions, {
  token: "e3468f8470bd9bff626cc9a80dfc9cceb5d01020",
  type: "ADDRESS",
});
Vue.use(VueYandexMetrika, {
  id: 98230031,
  router: router,
  env: "production",
  clickmap: true,
  trackLinks: true,
  accurateTrackBounce: true,
  debug: true,
});
Vue.use(LoadScript);
Vue.use(VueScrollactive);
Vue.component("v-select", vSelect);
Vue.use(VTooltip);
Vue.filter("text", typograf);
Vue.use(VueSmoothScroll, {
  offset: -150,
  duration: 400,
  updateHistory: false,
});
Vue.use(VueTheMask);
Vue.use(VModal);
Vue.use(Vuelidate);
function initWidget() {
  const t = this;
  const BASE_API = `${process.env.VUE_APP_URL}`;

  if (window.promoTools)
    window.promoTools.checkGrabberWidget.settings = {
      skin: "default",
      events: {
        onSentSuccess(res) {
          store.dispatch("GetChecks");
          store.dispatch("GetProfile");
          setMetrika('Успешная регистрация чека');
          localStorage.setItem("checkUploaded", true);
        },
        onSentError(res) {
          gtmEvent("", "check", "check_download_error", "qr");
        },
        onUseQr() {
          gtmEvent("", "check", "check_download_success", "qr");
        },
        onUsePhotos() {
          gtmEvent("", "check", "check_download_success", "photo");
        },
        onUseManual() {
          gtmEvent("", "check", "check_download_success", "manually");
        },
      },
      api: BASE_API,
      method: "/receipts/api/widgets/receipts",
      userUuid: store.getters?.user?.promocode,
      i18n: {
        qr: {
          title: "Сканирование",
          subTitle: "Наведите камеру на QR-код <br> <small>Пожалуйста, сохраняйте чек до конца акции</small>",
          btnQr: "Сканировать QR-код чека",
          btnManual: "Ввести данные вручную",
          btnPhotos: "Загрузить фото чека",
          cameraError: {
            title:
              "<strong>Ошибка</strong>Мы&nbsp;не&nbsp;можем получить доступ <br>к&nbsp;камере устройства. <br>Разрешите браузеру доступ к&nbsp;камере или введите данные с&nbsp;чека вручную.",
          },
        },
        photos: {
          title: "Загрузка фото",
          subTitle: "<small>Пожалуйста, сохраняйте чек до конца акции</small><br>Добавьте фото чека",
          btnPhotos: "Отправить",
        },
        manual: {
          title: "Ввести данные <br>с чека вручную",
          subTitle: `<small>Пожалуйста, сохраняйте чек до конца акции</small>`,
          btnManual: "Отправить",
        },
        success: {
          title: "Благодарим!",
          subTitle: "Ваш чек принят в обработку.<br> <small>Пожалуйста, сохраняйте чек до конца акции</small>",
        },
        pending: {
          title: "Подождите!",
          subTitle: "Идет отправка чека",
        },
        fail: {
          title: "Не удалось <br>отправить чек!",
        },
      },
    };
}
function gtmEvent(
  label = " ",
  site_section = " ",
  action = "click",
  eventContext = null
) {
  window?.dataLayer?.push({
    event: "interaction",
    site_section,
    action,
    label,
    eventContext,
  });
}
function setMetrika(i) {
  if (window.ym) {
    window.ym(98230031, "reachGoal", i);
  }
}

Vue.config.productionTip = false;
Vue.mixin({
  methods: {
    setMetrika(i) {
      if (window.ym) {
        window.ym(98230031, "reachGoal", i);
      }
    },
    gtmEvent: (label, action, category) => {
      window.dataLayer?.push({
        event: "gaEvent",
        eventCategory: category || "",
        eventAction: action || "",
        eventLabel: label || "",
      });
    },
    rememberSoc(item) {
      localStorage.socialName = item;
    },
    toCheckUpload() {
      const t = this;
      localStorage.setItem('checkUploaded', false);
      t.setMetrika('Клик на кнопку Зарегистрировать чек');
      initWidget();
      if (this.$store.getters.user) {
        window.promoTools.checkGrabberWidget.show();

        setTimeout(() => {
          let close = document.querySelector(
            "#promo-tools .popup__close"
          );
          close.addEventListener("click", function () {
            if (localStorage.getItem('checkUploaded') != true) {
              t.setMetrika('Брошенная регистрация чека');
            }
            
          });
        }, 1000);
      } else {
        this.$modal.show("authorization");
      }
    },
  },
});
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
