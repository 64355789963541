<template>
  <div class="signup">
    <div class="signup__fields">
      <inputText
        label="ФИО"
        :field="first_name"
        :class="{
          error: validationStatus($v.first_name) || errorMessage.first_name,
        }"
        :error="
          (!$v.first_name.required && $v.first_name.$error
            ? 'Обязательное поле'
            : !$v.first_name.rus && $v.first_name.$error
            ? 'Введите текст на русском языке'
            : '') || errorMessage.first_name
        "
        @input="first_name = $event"
      />

      <inputText
        label="E-mail"
        :field="email"
        type="email"
        :class="{
          error: validationStatus($v.email) || this.errorMessage.email,
        }"
        :error="
          (!$v.email.required && $v.email.$error
            ? 'Обязательное поле'
            : !$v.email.email && $v.email.$error
            ? 'Некорректный e-mail'
            : '') || this.errorMessage.email
        "
        @input="email = $event"
      />
      <inputText
        label="Телефон"
        :field="phone"
        @input="phone = $event"
        type="tel"
        :mask="'+7(###) ###-##-##'"
        :class="{
          error: validationStatus($v.phone) || this.errorMessage.phone,
        }"
        :error="
          (!$v.phone.required && $v.phone.$error
            ? 'Обязательное поле'
            : !$v.phone.minLength && $v.phone.$error
            ? 'Минимум 11 символов'
            : '') || this.errorMessage.phone
        "
      />
    </div>

    <div class="signup__checkbox_list">
      <checkBox
        color="white"
        :label="`Я согласен(-на) с <a href='${publicPath}docs/rules.pdf' class='_undeline-link _red' target='_blank'>правилами акции</a>, с&nbsp;<a href='${publicPath}docs/terms-of-use.pdf' class='_undeline-link _red' target='_blank'>пользовательским соглашением</a> и&nbsp;с&nbsp;<a href='${publicPath}docs/terms-of-use.pdf' class='_undeline-link _red' target='_blank'>положением о&nbsp;конфиденциальности</a> `"
        :field="agree_pd"
        :class="{
          error: validationStatus($v.agree_pd) || errorMessage.agree_pd,
        }"
        :error="errorMessage.agree_pd"
        @input="agree_pd = $event"
      />
    </div>
    <div class="signup__footer">
      <button
        type="button"
        class="btn btn--primary w-100"
        @click="submit()"
        :class="{ loading: submitStatus == 'PENDING' }"
      >
        <span>Зарегистрироваться</span>
        <img src="../../assets/img/spinner.svg" class="loader" alt="" />
      </button>
      <div class="modal__another-option">
        <p>
          Уже есть аккаунт?
          <a href="#" class="_red" @click="showLogInModal()">Войти</a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import inputText from "@/components/form/inputText.vue";
import checkBox from "@/components/form/checkBox.vue";

import {
  required,
  email,
  minLength,
  maxLength,
  sameAs,
} from "vuelidate/lib/validators";
const rus = (value) => /^[А-ЯЁа-яё ]+$/.test(value);

export default {
  name: "SignupForm",
  props: ["network"],
  data() {
    return {
      publicPath: process.env.BASE_URL,
      ready: false,
      email: null,
      first_name: null,
      phone: null,
      // password: null,
      // repPassword: null,
      agree_pd: false,

      errorMessage: {
        email: null,
        first_name: null,
        phone: null,
        // password: null,
        // repPassword: null,
        agree_pd: null,
      },

      submitStatus: null,
    };
  },

  validations: {
    email: { required, email },
    first_name: { required, rus },
    phone: { required, minLength: minLength(11) },

    agree_pd: {
      required,
      sameAs: sameAs(() => true),
    },
  },
  watch: {
    checkbox(newVal) {
      if (!newVal) {
        this.$store.commit("SET_AGREE", false);
      }
    },
  },
  methods: {
    selectSuggestion(suggestion) {
      this.city = suggestion.data.city;
    },
    errorReset() {
      this.$v.$reset();

      this.errorMessage = {
        email: null,
        first_name: null,
        phone: null,

        // repPassword: null,
        // password: null,
        agree_pd: null,
      };
    },
    showRules() {
      if (!this.$store.getters.agree) {
        this.$modal.show("document_popup", {
          src: this.publicPath + "docs/rules.pdf",
        });
      }
    },
    validationStatus: function (validation) {
      return typeof validation != "undefined" ? validation.$error : false;
    },
    setFormErrors(e) {
      const errors = e.response.data;
      this.submitStatus = null;

      let fieldsError = null;

      for (let index = 0; index < errors.length; index++) {
        const element = errors[index];
        this.errorMessage[element.field] = element.message;
        fieldsError = true;
      }

      if (errors && !fieldsError) {
        this.$modal.show("common_error", {
          text: "Что-то пошло не так! " + errors[0].message,
        });
      }
    },
    submit() {
      this.$v.$touch();
      if (this.$v.$pendding || this.$v.$error) return;

      if (this.submitStatus !== "PENDING") {
        this.submitStatus = "PENDING";
        const ya_id = this.$metrika?.getClientID() ? this.$metrika?.getClientID() : null;
        const formData = {
          email: this.email,
          first_name: this.first_name,
          phone: this.phone,
          // pass: this.password,
          // city: this.city,
          ya_id,
          agree_pd: "yes",
        };
        this.$store
          .dispatch("SignUp", formData)
          .then((r) => {
            this.$modal.hide("signup");
            
            this.$modal.show("success_signup", { email: this.email });
            this.setMetrika('Успешная регистрация пользователя');
            // this.$store.commit("SET_TOKEN", r.data[0]);

            // this.$store.dispatch("GetProfile").then(() => {
            //   this.$router.push({ name: "Profile"});
            // });
          })
          .catch((e) => {
            console.log(e.response);
            if (e.response) {
              this.setFormErrors(e);
            }
          });
      }
    },
    showLogInModal() {
      this.$modal.hide("signup");
      this.$modal.show("authorization");
    },
  },
  mounted() {
    console.log(this.$route);
  },
  components: {
    inputText,
    checkBox,
  },
};
</script>
<style lang="scss">
.suggestions-hint {
  display: none;
}
.suggestions-wrapper {
  position: absolute !important;
  margin-top: rem(10px) !important;
  width: 100%;
  z-index: 100;
}
.suggestions-suggestions {
  border: none !important;
  border-radius: rem(0px);
  overflow: auto;
  border: 1px solid $yellow;
}
.suggestions-suggestion {
  padding: rem(10px) rem(20px) !important;
  &:hover {
    background: rgba($green-light, 0.5) !important;
  }
}
.suggestions-value {
  font-size: rem(16px);
  strong {
    font-weight: bold !important;
    color: $red !important;
  }
}
</style>
<style lang="scss" scoped>
.signup {
  &__row {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  &__checkbox_list {
    margin-top: rem(15px);
    margin-bottom: rem(20px);
  }
  &__footer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  @media (min-width: $sm) {
    &__row {
      flex-direction: row;
    }
    &__checkbox_list {
      margin-top: rem(30px);
      margin-bottom: rem(30px);
    }
  }
}
</style>
