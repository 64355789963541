<template>
  <div class="hero section" id="home">
    <div class="container">
      <div class="hero__subtitle">
        Участвуйте <br class="visible-xs" />и выигрывайте
      </div>
      <div class="hero__title">высокогорное <br />путешествие</div>
      <div class="hero__suptitle">2 сентября – 3 ноября 2024</div>
      <img src="../assets/images/products.png" alt="" class="hero__products" />
      <div class="hero-block">
        <button
          class="btn btn--primary"
          @click="
            toCheckUpload(),
              gtmEvent('register_receipt_1_screen', 'click', 'internal')
          "
        >
          <span>зарегистрировать чек</span>
        </button>
        <a
          :href="`${publicPath}docs/rules.pdf`"
          @click="
            gtmEvent('rules', 'click', 'send'),
            gtmEvent('rules', 'click', 'internal'),
            setMetrika('Клик на кнопку Полные правила акции')
          "
          class="hero__rules _undeline-link"
          target="_blank"
          >Полные правила акции</a
        >
      </div>
    </div>
    <div class="hero__plants hidden-xs">
      <img
        v-for="i in 3"
        :key="i"
        :src="require(`../assets/images/animations/hero/plant-${i}.png`)"
        class="hero__plant hidden-xs"
        alt=""
      />
    </div>
    <div class="hero__clouds hidden-xs">
      <img
        v-for="i in 4"
        :key="i"
        :src="require(`../assets/images/animations/hero/cloud-${i}.svg`)"
        class="hero__cloud"
        alt=""
      />
    </div>
    <div class="hero__parallax-wrap js-parallax-wrap hidden-xs">
      <img
        src="../assets/images/animations/hero/sky.png"
        class="hero__sky hidden-xs"
        alt=""
      />
      <img
        value="3"
        src="../assets/images/animations/hero/mountain.png"
        class="hero__mountain hidden-xs"
        alt=""
      />
      <img
        value="2"
        src="../assets/images/animations/hero/lake.png"
        class="hero__lake hidden-xs"
        alt=""
      />
      <div value="1" class="hero__family-wrapper">
        <img
          src="../assets/images/animations/hero/family.png"
          class="hero__family hidden-xs"
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    // Feedback,
  },
  data: () => ({
    publicPath: process.env.BASE_URL,
  }),
  computed: {
    user() {
      return this.$store.getters.user;
    },
  },
  methods: {
    showModal(name) {
      this.$modal.show(name);
    },
  },
  mounted() {
    // this.toCheckUpload()
    document.querySelector(".hero").addEventListener("mousemove", parallax);
    function parallax(event) {
      this.querySelectorAll(".js-parallax-wrap > *").forEach((shift) => {
        const position = shift.getAttribute("value");
        const x = (window.innerWidth - event.pageX * position) / 200;

        shift.style.transform = `translateX(${x}px)`;
      });
    }
  },
};
</script>
<style lang="scss">
.hero {
  position: relative;
  display: flex;
  align-items: center;
  padding-top: rem(70px);
  flex-direction: column;
  background: url("../assets/images/hero.png") no-repeat center top;
  background-size: 100%;
  z-index: 3 !important;
  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    height: rem(145px);
    background: url("../assets/images/steps/bg-m.svg") no-repeat center top;
    background-size: 100%;
  }
  .container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
    color: #fff;
    z-index: 30;
  }
  &__monetka {
    display: none;
  }
  &__subtitle {
    margin-bottom: rem(5px);
    font-size: rem(16px);
    line-height: 1.3;
    text-transform: uppercase;
    font-family: $medium;
  }
  &__title {
    margin-bottom: rem(8px);
    font-size: rem(44px);
    font-family: $alt-font;
    text-transform: uppercase;
    line-height: 0.85;
  }
  &__suptitle {
    margin-bottom: rem(38px);
    font-size: rem(14px);
    line-height: 1;
    font-family: $medium;
  }
  &__products {
    height: rem(169px);
    margin-bottom: rem(14px);
  }
  &-block {
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__rules {
    position: relative;
    margin-top: rem(10px);
    font-size: rem(20px);
    color: $brown;
    line-height: 1;
    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      @include size(100%, 250%);
      display: block;
      transform: translate(-50%, -50%);
    }
  }

  &__cloud {
    position: absolute;
    display: block;
    z-index: 100;
    &:nth-of-type(1) {
      top: rem(115px);
      left: 6%;
      height: rem(90px);
      animation: cloud 15s linear infinite;
    }
    &:nth-of-type(2) {
      top: rem(250px);
      left: 3%;
      height: rem(60px);
      animation: cloud 15s linear infinite;
    }
    &:nth-of-type(3) {
      top: rem(62px);
      left: 60%;
      height: rem(70px);
      animation: cloud2 15s linear infinite;
    }
    &:nth-of-type(4) {
      top: rem(62px);
      left: 60%;
      height: rem(90px);
      animation: cloud2 15s linear infinite;
    }
  }
  @media (min-width: $sm) {
    position: relative;
    flex-direction: column;
    min-height: 100vh;
    padding-top: 0;
    padding-bottom: 0;
    background: none;
    .container {
      position: relative;
      padding-top: vh(130px);
    }
    &__palm {
      bottom: rem(-20px);
      height: rem(777px);
      right: 0;
    }
    &__cloud {
      position: absolute;
      z-index: 0;
      &:nth-of-type(1) {
        top: rem(75px);
        left: calc(54% - vh(150px));
        height: rem(80px);
        animation: cloud 50s 3s linear infinite;
        transform: translateX(-100%);
      }
      &:nth-of-type(2) {
        top: rem(-10px);
        left: calc(30% - vh(150px));
        height: rem(60px);
        animation: cloud 65s linear infinite;
        transform: translateX(-100%);
      }
      &:nth-of-type(3) {
        top: rem(142px);
        left: calc(56% + vh(150px));
        height: rem(70px);
        animation: cloud2 55s 2s linear infinite;

        transform: translateX(100%);
      }
      &:nth-of-type(4) {
        top: rem(12px);
        left: calc(55% + vh(150px));
        height: rem(70px);
        animation: cloud2 45s linear infinite;
        transform: translateX(100%);
      }
    }
    &__plant {
      position: absolute;
      bottom: 0;
      animation: plant 5s ease-in-out infinite forwards;
      transform-origin: bottom center;
      z-index: 1;
      &:nth-of-type(1) {
        bottom: vh(143px);
        left: vw(500px);
        height: vh(276px);
      }
      &:nth-of-type(2) {
        bottom: vh(260px);
        left: calc(50% + vh(500px));
        height: vh(125px);
        animation-delay: 0.5s;
      }
      &:nth-of-type(3) {
        bottom: vh(240px);
        left: calc(50% + vh(680px));
        height: vh(225px);
        transform-origin: bottom left;
        animation-delay: 0.8s;
      }
    }
    &__parallax-wrap {
      position: absolute;
      width: 100%;
      left: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 100vh;
      overflow: hidden;
    }
    &__sky {
      position: absolute;
      top: 0;
      height: 46vh;
      z-index: -1;
    }
    &__mountain {
      max-width: none;
      height: 57.895vh;
    }
    &__lake {
      max-width: none;
      height: 39.053vh;
    }
    &__family {
      max-width: none;
      height: 72vh;
      &-wrapper {
        position: absolute;
        bottom: 0;
        left: 0%;
        width: 100%;
        display: flex;
        justify-content: center;
        z-index: 10;
      }
    }
    &__subtitle {
      margin-bottom: vh(12px);
      font-size: vh(44px);
      letter-spacing: vh(1px);
      text-transform: uppercase;
      text-shadow: 0 0 rem(6px) rgba($black, 0.5);
    }
    &__title {
      margin-bottom: vh(28px);
      font-size: vh(120px);
      line-height: vh(102px);
      text-shadow: 0 0 rem(6px) rgba($black, 0.5);
    }
    &__suptitle {
      margin-bottom: vh(70px);
      font-size: vh(26px);
      letter-spacing: vh(0.5px);
    }
    &__products {
      height: vh(420px);
      margin-bottom: 0;
    }
    &-block {
      position: absolute;
      right: rem(15px);
      top: vh(150px);
      padding: rem(30px);
      display: flex;
      flex-direction: column;
      align-items: center;
      background: $yellow;
      border-radius: rem(30px);
    }
    &__rules {
      margin-top: rem(20px);
      font-size: rem(22px);
    }
  }
  @media (min-width: $md) and (max-height: 750px) {
  }

  @media (max-width: $xlg) {
  }
}
</style>
