var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hero section",attrs:{"id":"home"}},[_c('div',{staticClass:"container"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"hero__suptitle"},[_vm._v("2 сентября – 3 ноября 2024")]),_c('img',{staticClass:"hero__products",attrs:{"src":require("../assets/images/products.png"),"alt":""}}),_c('div',{staticClass:"hero-block"},[_c('button',{staticClass:"btn btn--primary",on:{"click":function($event){_vm.toCheckUpload(),
            _vm.gtmEvent('register_receipt_1_screen', 'click', 'internal')}}},[_c('span',[_vm._v("зарегистрировать чек")])]),_c('a',{staticClass:"hero__rules _undeline-link",attrs:{"href":`${_vm.publicPath}docs/rules.pdf`,"target":"_blank"},on:{"click":function($event){_vm.gtmEvent('rules', 'click', 'send'),
          _vm.gtmEvent('rules', 'click', 'internal'),
          _vm.setMetrika('Клик на кнопку Полные правила акции')}}},[_vm._v("Полные правила акции")])])]),_c('div',{staticClass:"hero__plants hidden-xs"},_vm._l((3),function(i){return _c('img',{key:i,staticClass:"hero__plant hidden-xs",attrs:{"src":require(`../assets/images/animations/hero/plant-${i}.png`),"alt":""}})}),0),_c('div',{staticClass:"hero__clouds hidden-xs"},_vm._l((4),function(i){return _c('img',{key:i,staticClass:"hero__cloud",attrs:{"src":require(`../assets/images/animations/hero/cloud-${i}.svg`),"alt":""}})}),0),_vm._m(2)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hero__subtitle"},[_vm._v(" Участвуйте "),_c('br',{staticClass:"visible-xs"}),_vm._v("и выигрывайте ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hero__title"},[_vm._v("высокогорное "),_c('br'),_vm._v("путешествие")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hero__parallax-wrap js-parallax-wrap hidden-xs"},[_c('img',{staticClass:"hero__sky hidden-xs",attrs:{"src":require("../assets/images/animations/hero/sky.png"),"alt":""}}),_c('img',{staticClass:"hero__mountain hidden-xs",attrs:{"value":"3","src":require("../assets/images/animations/hero/mountain.png"),"alt":""}}),_c('img',{staticClass:"hero__lake hidden-xs",attrs:{"value":"2","src":require("../assets/images/animations/hero/lake.png"),"alt":""}}),_c('div',{staticClass:"hero__family-wrapper",attrs:{"value":"1"}},[_c('img',{staticClass:"hero__family hidden-xs",attrs:{"src":require("../assets/images/animations/hero/family.png"),"alt":""}})])])
}]

export { render, staticRenderFns }